// import { Message } from "element-ui";
import { ElMessage } from 'element-plus'
import * as echarts from "echarts/core";
import "echarts/theme/macarons.js";

/*
el-Message提示框
 */
// export function elMessage(msg, type) {
//     return Message({
//         showClose: true,
//         message: msg,
//         type: type
//     });
// }

export function elMessgae(msg,type) {
    return ElMessage({
        message: msg,
        type: type,
    })
}

/*
获取某个日期的前后天日期
 */
export function getNextDate(date, day) {
    var dd = new Date(date);
    dd.setDate(dd.getDate() + day);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
    var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    return y + "-" + m + "-" + d;
}

/*
生成随机数
 */
export function getRandom(value) {
    return Math.round(Math.random() * value);
}

/*
获取输入天日期(格式化yyyy-mm-dd)
 */
export function getDateForDay(day) {
    var dates = [];
    var days = [];
    for (var d = 1; d < day + 1; d++) {
        days.push(d - day);
    }
    var date1 = new Date(),
        // eslint-disable-next-line no-unused-vars
        time1 =
            date1.getFullYear() +
            "-" +
            (date1.getMonth() + 1) +
            "-" +
            date1.getDate(); //time1表示当前时间
    for (var i = 0; i < days.length; i++) {
        var date2 = new Date(date1);
        date2.setDate(date1.getDate() + days[i]);
        dates.push(
            date2.getFullYear() +
            "-" +
            (date2.getMonth() + 1 < 10
                ? "0" + (date2.getMonth() + 1)
                : date2.getMonth() + 1) +
            "-" +
            (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate())
        );
    }
    return dates;
}

/*
获取输入日期范围
 */
export function getDateDiffDay(day, end_date, start_time_hms, end_time_hms) {
    var dates = [];
    var days = [];
    for (var d = 1; d < day + 1; d++) {
        days.push(d - day);
    }
    var date1 = new Date(end_date),
        // eslint-disable-next-line no-unused-vars
        time1 =
            date1.getFullYear() +
            "-" +
            (date1.getMonth() + 1) +
            "-" +
            date1.getDate(); //time1表示当前时间
    for (var i = 0; i < days.length; i++) {
        var date2 = new Date(date1);
        date2.setDate(date1.getDate() + days[i]);
        if (i > 0 && i < days.length - 1) {
            dates.push(
                date2.getFullYear() +
                "-" +
                (date2.getMonth() + 1 < 10
                    ? "0" + (date2.getMonth() + 1)
                    : date2.getMonth() + 1) +
                "-" +
                (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()) +
                " 00:00:00"
            );
        } else if (i == 0) {
            dates.push(
                date2.getFullYear() +
                "-" +
                (date2.getMonth() + 1 < 10
                    ? "0" + (date2.getMonth() + 1)
                    : date2.getMonth() + 1) +
                "-" +
                (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()) +
                " " +
                start_time_hms
            );
            if (days.length == 1) {
                dates.push(
                    date2.getFullYear() +
                    "-" +
                    (date2.getMonth() + 1 < 10
                        ? "0" + (date2.getMonth() + 1)
                        : date2.getMonth() + 1) +
                    "-" +
                    (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()) +
                    " " +
                    end_time_hms
                );
            }
        } else if (i == days.length - 1) {
            dates.push(
                date2.getFullYear() +
                "-" +
                (date2.getMonth() + 1 < 10
                    ? "0" + (date2.getMonth() + 1)
                    : date2.getMonth() + 1) +
                "-" +
                (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()) +
                " " +
                end_time_hms
            );
        }
    }
    return dates;
}

/*
获取过去一年的年月份(包括本月)
 */
export function getPreMonth(date, num) {
    var arr = date.split("-");
    var year = arr[0]; //获取当前日期的年份
    var month = arr[1]; //获取当前日期的月份
    // eslint-disable-next-line no-unused-vars
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中月的天数
    var year2 = year;
    var ym_datas = [];
    for (var i = 0; i < num; i++) {
        var month2 = parseInt(month) - i;
        if (month2 <= 0) {
            //如果是1月份，则取上一年的12月份
            year2 = parseInt(year) - 1;
            month2 = 12 + month2;
        }
        if (month2 < 10) {
            month2 = "0" + month2; //月份填补成2位。
        }
        var t2 = year2 + "-" + month2;
        ym_datas.push(t2);
    }
    return ym_datas.sort();
}

export function shortcutsValue() {
    return [
        {
            text: '昨天',
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                return [start, end]
            },
        },
        {
            text: '上周',
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                return [start, end]
            },
        },
        {
            text: '上个月',
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                return [start, end]
            },
        },
        {
            text: '上一年',
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                return [start, end]
            },
        }]
}

export function echarts_line(myeharts,seriesDataList,xData) {
    const color = ['#ba7a7c', '#906BF9', '#8f3434', '#01b65b', '#3DD1F9', '#FFAD05']; //2个以上的series就需要用到color数组
    const legend = {
        //data，就是取得每个series里面的name属性。
        orient: 'horizontal',
        icon: 'circle', //图例形状
        padding: 0,
        top: 70,
        right: 70,
        itemWidth: 14, //小圆点宽度
        itemHeight: 14, // 小圆点高度
        itemGap: 21, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
        textStyle: {
            fontSize: 12,
            color: '#000000',
            fontWeight: '300'
        },
    };
    const tooltip = {
        show: true,
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
        textStyle:{
            color:'#5a5a5a',
            fontSize:14,
            fontWeight: '300'
        },
        backgroundColor:'rgba(255,255,255,0.5)',
        borderColor:'rgba(255,255,255,0.5)'
    };
    let seriesData = seriesDataList;
    const commonConfigFn = (index) => {
        return {
            type: 'line',
            smooth: true,
            symbol: 'emptyCircle', //空心小圆点。线条小圆点形状
            symbolSize: 6, //小圆点大小，
            itemStyle: {
                //还是小圆点设置
            },

            label: {
                show: false, //不显示小圆点上的label文字
            },
            lineStyle: {
                width: 1, //线条设置

            },

            areaStyle: {
                //填充线条下面的面积区域颜色。（areaStyle只是锦上添花）
                opacity: 0.2,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: color[index], // 上处的颜色
                        },
                        {
                            offset: 1,
                            color: 'transparent', // 下处的颜色
                        },
                    ],
                    global: false, // 缺省为 false
                },
            },
        };
    };
    seriesData = seriesData.map((item, index) => ({ ...item, ...commonConfigFn(index) }));
    var option = {
        backgroundColor:'rgba(255,255,255,0.5)',
        color,
        tooltip,
        legend,
        grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '5%',
            containLabel: true,
        },

        xAxis: {
            show: true, //显示x轴+x轴label文字
            type: 'category',
            boundaryGap: false, //从Y轴出发，这个false很好的
            axisLine: {
                show: true, //显示x坐标轴轴线
                lineStyle: {
                    color: 'rgba(255,255,255,.4)',
                },
            },
            axisTick: {
                show: false, //不显示x坐标1cm刻度
            },
            axisLabel: {
                color: '#000000', //x轴label文字颜色
                fontWeight: '300'
            },
            splitLine: {
                show: false, //不显示grid竖向分割线
            },

            data: xData,
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                color: '#000000',
                fontWeight: '300'
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(255,255,255,.4)',
                    fontWeight: '300'
                },
            },
            splitLine: {
                show: false, //不显示grid水平分割线
            },
        },

        series: seriesData,
    }
    myeharts.clear();
    myeharts.setOption(option, true);
}

export function echarts_cyun(myeharts,dataList) {
    // const list = [
    //     {
    //         value: '50',
    //         name: '华为',
    //         textStyle: {
    //             shadowBlur: 4,
    //             shadowColor: '#ECEFFF',
    //             shadowOffsetY: 14,
    //             color: '#73DDFF',
    //         },
    //     }, // 50
    //     { value: '30', name: 'VIVO' },
    //     { value: '29', name: 'OPPO' },
    //     { value: '28', name: 'HONOR' },
    //     { value: '27', name: '红米' },
    //     { value: '26', name: '小米' },
    //     { value: '25', name: '美图' },
    //     { value: '24', name: 'ONEPLUS' },
    //     { value: '23', name: '魅族' },
    //     { value: '22', name: '红手指' },
    //     { value: '21', name: 'SAMSUNG' },
    //     { value: '20', name: '金立' },
    //     { value: '16', name: 'BLACKBERRY' },
    //     { value: '15', name: '诺基亚' },
    //     { value: '14', name: '锤子' },
    //     { value: '13', name: '大疆' },
    //     { value: '12', name: '361' },
    //     { value: '11', name: '摩托罗拉' },
    //     { value: '10', name: '联想' },
    //     { value: '9', name: '玩家国度' },
    // ];
    const list = dataList;
    var option = {
        tooltip: {
            show: true,
            borderColor: '#fe9a8bb3',
            borderWidth: 1,
            padding: [10, 15, 10, 15],
            confine: true,
            backgroundColor: 'rgba(255, 255, 255, .9)',
            textStyle: {
                color: 'hotpink',
                lineHeight: 22
            },
            extraCssText: 'box-shadow: 0 4px 20px -4px rgba(199, 206, 215, .7);border-radius: 4px;'
        },
        series: [
            {
                type: 'wordCloud',
                shape: 'pentagon',
                left: 'center',
                top: 'center',
                width: '100%',
                height: '100%',
                right: null,
                bottom: null,
                sizeRange: [14, 50],
                rotationRange: [0, 0],
                rotationStep: 0,
                gridSize: 25,
                drawOutOfBound: false,
                layoutAnimation: true,
                textStyle: {
                    fontFamily: 'PingFangSC-Semibold',
                    fontWeight: 600,
                    color: function (params) {
                        let colors = ['#fe9a8bb3', '#fe9a8bb3', '#fe9a8b03', '#9E87FFb3', '#9E87FFb3', '#9E87FFb3', '#fe9a8bb3', '#fe9a8bb3', '#fe9a8bb3', '#73DDFF', '#58D5FF']
                        return colors[parseInt(Math.random() * 10)];
                    },
                },
                emphasis: {
                    focus: 'none',
                },
                data: list,
            },
        ],
    };
    myeharts.clear();
    myeharts.setOption(option, true);
}

export function echarts_pie(myeharts,data) {
    let chartData = data;
// 字段值对齐，不足补空格
    let angle = 0;//角度，用来做简单的动画效果的
    var option = {
        color: ['#1EE7E7', '#BEE5FB', '#00D68A', '#1890FF', '#564AF1'],
        tooltip: {
            confine: true,
        },
        legend: {
            selectedMode: true,
            orient: 'vertical',
            right: '0%',
            top: 'center',
            itemWidth: 30,
            itemHeight: 30,
            formatter(name) { // 该函数用于设置图例显示后的百分比
                let data = chartData
                let total = 0
                let value = 0
                data.forEach((item) => {
                    total += item.value
                    if (item.name == name) {
                        value = item.value
                    }
                })
                let p = ((value / total) * 100).toFixed(1) // 求出百分比
                return ['{a|' + `${name}` + '}{b|' + `${value}` + '}{b1|项}{c|' + `${p || '0'}` + '}{c1|%' + '}%']
            },
            textStyle: {
                fontSize: 12,
                color: '#000000',
                padding: [2, 0],
                rich: {
                    b: {
                        padding: [0, 2, 0, 20],
                        verticalAlign: 'sub',
                        fontSize: 12,
                        color: '#000000'
                    },
                    b1: {
                        color: 'rgba(255, 255, 255, 0.65)',
                        fontSize: 12,
                    },
                    c: {
                        padding: [0, 2, 0, 20],
                        fontSize: 12,
                        color: '#000000'
                    },
                    c1: {
                        color: 'rgba(255, 255, 255, 0.65)'
                    },
                }
            }
        },
        graphic: {
            elements: [{
                type: 'image',
                z: 4,
                left: '30.5%',
                top: 'center',
            }],
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['30%', '35%'],
                center: ['35%', '50%'],
                hoverAnimation: false,
                avoidLabelOverlap: false,
                itemStyle: {
                    // borderRadius: 10,
                    borderColor: '#071649',
                    borderWidth: 8,
                    normal: {
                        //饼状图阴影，值越大阴影亮度越高
                        shadowBlur: 10,
                        shadowColor: '#62e3e1'
                    }
                },
                label: {
                    show: false,
                    position: 'center',
                },
                labelLine: {
                    show: false,
                },
                data: chartData,
            },

        ],
    };
    myeharts.clear();
    myeharts.setOption(option, true)
    // function draw() {
    //     angle = angle + 3;
    //     myeharts.clear();
    //     myeharts.setOption(option, true)
    // }
    //
    // setInterval(function () {
    //     //用setInterval做动画感觉有问题最好的方法就是代码内定位图片然后加css3动画效果
    //     draw()
    // }, 100000000);
}


export function echarts_line2(myeharts,y1,y2,y3,xData) {
    var legend = ["销量", "交易额", "货量"];
    var colorArr = [{
        start: "rgba(2, 201, 254,",
        end: "rgba(17, 107, 243, 0.6)"
    },
        {
            start: "rgba(48, 209, 188,",
            end: "rgba(29, 152, 127, 0.6)"
        },
        {
            start: "rgba(205, 178, 73,",
            end: "rgba(160, 121, 60, 0.6)"
        },
        {
            color: "#EC6941"
        }
    ];
    var normalColor = "#DEEBFF";
    var borderData = [];
    let seriesData = [];
    var borderHeight = 4;
    xData.forEach(element => {
        borderData.push(borderHeight);
    });
    [y1, y2, y3].forEach((item, index) => {
        var obj1 = {};
        if (index < 3) {
            obj1 = {
                name: legend[index],
                type: "bar",
                stack: legend[index],
                data: item,
                barWidth: "15%",
                itemStyle: {
                    normal: {
                        color: {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: colorArr[index].start + "0.7)"
                            },
                                {
                                    offset: 0.5,
                                    color: colorArr[index].start + "0.3)"
                                },
                                {
                                    offset: 1,
                                    color: colorArr[index].end
                                }
                            ],
                            globalCoord: false
                        }
                    }
                }
            };
            // obj2 = {
            //     name: "",
            //     type: "bar",
            //     stack: legend[index],
            //     itemStyle: {
            //         barBorderRadius: '2px',
            //         normal: {
            //             color: colorArr[index].start + "1)"
            //         }
            //     },
            //     data: borderData
            // };
            seriesData.push(obj1);
            // seriesData.push(obj2);
        } else {
            var obj3 = {
                name: legend[index],
                type: "line",
                yAxisIndex: 1,
                smooth: false,
                symbol: "circle",
                symbolSize: 8,
                lineStyle: {
                    normal: {
                        width: 2
                    }
                },
                itemStyle: {
                    normal: {
                        color: colorArr[index].color,
                        borderColor: "#fff",
                        borderWidth: 1
                    }
                },
                data: item,
                label: {
                    normal: {
                        show: false
                    }
                }
            };
            seriesData.push(obj3);
        }
    });

    var option = {
        backgroundColor: "rgb(255,255,255,0)",
        grid: {
            left: "3%",
            top: "12%",
            right: "3%",
            bottom: '2%',
            containLabel: true
        },
        legend: {
            show: true,
            // icon: "rect",
            itemWidth: 16,
            itemHeight: 9,
            right: "15%",
            top: "0%",
            textStyle: {
                color: "rgba(0,0,0,0.7)"
            },
            data: legend
        },
        tooltip: {
            trigger: "axis",
            backgroundColor: 'rgba(18, 57, 60, .8)', //设置背景颜色
            textStyle: {
                color: 'rgba(255,255,255,0.7)'
            },
            borderColor: "rgba(18, 57, 60, .8)",
            axisPointer: {
                type: 'shadow',
                shadowStyle: {
                    color: 'rgba(0, 11, 34, .4)',
                }
            },
            formatter: function(params) {
                var str = "";
                for (var i = 0; i < params.length; i++) {
                    if (params[i].seriesName !== "") {
                        str +=
                            params[i].name +
                            ':' +
                            params[i].seriesName +
                            '-' +
                            params[i].value +
                            "<br/>";
                    }
                }
                return str;
            }
        },
        xAxis: [{
            type: "category",
            data: xData,
            axisPointer: {
                type: "shadow"
            },
            axisLabel: {
                interval: 0,
                align: 'center',
                textStyle: {
                    color: 'rgba(0,0,0,0.7)',
                    fontSize: 12
                }
            },
            axisLine: {
                lineStyle: {
                    color: '#1C82C5'
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        }],
        yAxis: [{
            type: "value",
            // name: "亿千瓦时",
            // nameTextStyle: {
            //     color: normalColor,
            //     fontSize: 12
            // },
            // "min": 0,
            // "max": 50,
            axisLabel: {
                formatter: "{value}",
                textStyle: {
                    color: 'rgba(0,0,0,0.7)',
                    fontSize: 14
                }
            },
            axisLine: {
                lineStyle: {
                    color: '#1C82C5'
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: "solid",
                    color: 'rgba(28, 130, 197, .3)'
                }
            }
        },
            {
                type: "value",
                // name: "%",
                // nameTextStyle: {
                //     color: normalColor,
                //     fontSize: 12
                // },
                // min: -100,
                // max: 100,
                axisLabel: {
                    formatter: "{value}",
                    textStyle: {
                        color: '#848484',
                        fontSize: 12
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: '#1C82C5'
                    }
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: "dashed",
                        color: "rgba(255,255,255,0.2)"
                    }
                }
            }
        ],
        series: seriesData
    };
    myeharts.clear();
    myeharts.setOption(option, true)
}

export function echarts_line3(myeharts,xData,data) {
    var option = {
        backgroundColor: '#fff',
        grid: {
            top: '4%',
            bottom: '4%',
            left: '4%',
            right: '2%'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer:{
                type:'line',
                lineStyle:{
                    color:'#FF8736'
                },
            }
        },
        xAxis: {
            boundaryGap: true, // 默认，坐标轴留白策略
            axisLine: { // 坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                    color: '#85B1B4'
                }
            },
            axisLabel: { // 坐标轴刻度标签的相关设置
                textStyle: {
                    color: '#709FD9',
                    margin: 15
                }
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: false,
                alignWithLabel: true
            },
            data: xData
        },
        yAxis: {
            axisLine: {
                show: false,
            },
            axisLabel: { // 坐标轴刻度标签的相关设置
                show:true,
                textStyle: {
                    color: '#709FD9',
                    margin: 15
                }
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            }
        },
        series: [{
            type: 'scatter',
            symbolSize: 10,
            itemStyle: {
                color:'#FF8736'
            },
            silent: true,
            tooltip:{
                show:false
            },
            data:data
        },{
            type: 'line',
            symbol: 'circle',
            symbolSize: 20,
            symbol:'path://M488 187.381L242.872 328.906a48 48 0 0 0-24 41.57v283.049a48 48 0 0 0 24 41.569L488 836.619a48 48 0 0 0 48 0l245.128-141.525a48 48 0 0 0 24-41.57V370.476a48 48 0 0 0-24-41.569L536 187.381a48 48 0 0 0-48 0z m32 27.713l245.128 141.525a16 16 0 0 1 8 13.856v283.05a16 16 0 0 1-8 13.856L520 808.906a16 16 0 0 1-16 0L258.872 667.381a16 16 0 0 1-8-13.856v-283.05a16 16 0 0 1 8-13.856L504 215.094a16 16 0 0 1 16 0z',
            lineStyle: {
                color: '#FF8736'
            },
            itemStyle: {
                color: '#eed9d1',
                borderWidth: 1,
                borderColor: '#FF8736'
            },
            label: {
                show: true,
                position: 'top',
                textStyle: {
                    color: '#9c8983'
                }
            },

            data: data
        }]
    };
    myeharts.clear();
    myeharts.setOption(option, true)
}

export function echarts_line4(myeharts,xAxisData,y1,y2,y3) {
    var title=['货量','交易额','销量']
    var option = {
        backgroundColor: 'rgba(255,255,255)',
        color: ["#029AFE"],
        tooltip: {
            trigger: "axis",
            textStyle: { color: "#fff", fontSize: 14 },
            className: "tooltip",
            order: "seriesDesc",
            renderMode: "html",
            backgroundColor: "#1b366a", // 设置背景颜色
            borderColor: "#1b366a", // 边框颜色
            formatter: (params) => {
                return `
            ${params[0].name}  <br/>
            ${params[0].marker}
            ${params[0].seriesName} :
            ${params[0].value == 0 ? "-" : Number(params[0].value).toLocaleString()}`;
            },
        },
        legend: {
            data: ["货量", "交易额", "销量"],
            top: "6%",
            right: 12,
            icon: "rect",
            itemHeight: 2,
            textStyle: {
                align: "right",
                color: "rgba(0,0,0,0.7)",
                fontSize: 13,
            },
            selectedMode: 'single',
        },
        grid: {
            left: 12,
            right: 12,
            bottom: "3%",
            containLabel: true,
        },
        xAxis: [
            {
                type: "category",
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    color: "rgba(0,0,0,0.7)",
                    fontSize: 13,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#ccc",
                    },
                },
                data: xAxisData,
            },
        ],
        yAxis: [
            {
                type: "value",
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: [6, 8],
                        dashOffset: 1,
                        color: "rgba(255, 255, 255, 0.1)",
                    },
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#ccc",
                    },
                },
                axisLabel: {
                    show: true,
                    color: "rgba(0,0,0,0.7)",
                    fontSize: 13,
                },
                axisTick: {
                    show: false,
                },
            },
        ],
        series: [
            {
                name: title[0],
                type: "line",
                data: y1,
                // symbol: "none",
                smooth: true,
                itemStyle : { normal: {label : {show: true}}},
                cursor: "default",
                width: 2,
                areaStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            [
                                {
                                    offset: 0,
                                    color: "rgba(2, 154, 254,.4)",
                                },
                                {
                                    offset: 1,
                                    color: "rgba(2, 154, 254, 0)",
                                },
                            ],
                            false
                        ),
                        shadowColor: "rgba(2, 154, 254, .3)",
                        shadowBlur: 2,
                    },
                },
            },
            {
                name: title[1],
                type: "line",
                data: y2,
                // symbol: "none",
                smooth: true,
                itemStyle : { normal: {label : {show: true}}},
                cursor: "default",
                width: 2,
                areaStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            [
                                {
                                    offset: 0,
                                    color: "rgba(2, 154, 254,.4)",
                                },
                                {
                                    offset: 1,
                                    color: "rgba(2, 154, 254, 0)",
                                },
                            ],
                            false
                        ),
                        shadowColor: "rgba(2, 154, 254, .3)",
                        shadowBlur: 2,
                    },
                },
            },
            {
                name: title[2],
                type: "line",
                data: y3,
                // symbol: "none",
                smooth: true,
                itemStyle : { normal: {label : {show: true}}},
                cursor: "default",
                width: 2,
                areaStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            [
                                {
                                    offset: 0,
                                    color: "rgba(2, 154, 254,.4)",
                                },
                                {
                                    offset: 1,
                                    color: "rgba(2, 154, 254, 0)",
                                },
                            ],
                            false
                        ),
                        shadowColor: "rgba(2, 154, 254, .3)",
                        shadowBlur: 2,
                    },
                },
            },
        ],
    };

    myeharts.clear();
    myeharts.setOption(option, true)
}

export function getTreeList(data) {
    var sz = [];
    var name = [];
    var list = [];
    for(var i = 0;i<data.length;i++){
        sz.push(data[i].deptId);
        name.push(data[i].dept.deptName);
    }
    var newArr = sz.filter(function(item,index){
        return sz.indexOf(item) === index;
    });
    var newArrName = name.filter(function(item,index){
        return name.indexOf(item) === index;
    });
    for(var i = 0;i<newArr.length;i++){
        list.push({
            value:newArr[i],
            label:newArrName[i],
            id:newArr[i],
            children:[]
        })
    }
    for(var i = 0;i<data.length;i++){
        for(var j = 0;j<list.length;j++){
            if(list[j].id===data[i].deptId){
                list[j].children.push({
                    value:data[i].userName,
                    label:data[i].userName
                })
            }
        }
    }
    return list;
}

export function getTreeList2(data) {
    var sz = [];
    var name = [];
    var list = [];
    for(var i = 0;i<data.length;i++){
        sz.push(data[i].deptId);
        name.push(data[i].dept.deptName);
    }
    var newArr = sz.filter(function(item,index){
        return sz.indexOf(item) === index;
    });
    var newArrName = name.filter(function(item,index){
        return name.indexOf(item) === index;
    });
    for(var i = 0;i<newArr.length;i++){
        list.push({
            value:newArr[i],
            label:newArrName[i],
            id:newArr[i],
            children:[]
        })
    }
    for(var i = 0;i<data.length;i++){
        for(var j = 0;j<list.length;j++){
            if(list[j].id===data[i].deptId){
                list[j].children.push({
                    id:data[i].id,
                    value:data[i].userName,
                    label:data[i].userName
                })
            }
        }
    }
    return list;
}


function downExcel(obj,fileName) {
    const a_node = document.createElement("a");
    a_node.download = fileName;
    if("msSaveOrOpenBlob" in navigator ){
        window.navigator.msSaveOrOpenBlob(obj,fileName);
    }else {
        a_node.href = URL.createObjectURL(obj);
    }
    a_node.click();
    setTimeout(() =>{
        URL.revokeObjectURL(obj);
    },2000);
}

function s2ab(s) {
    if(typeof ArrayBuffer !== "undefined"){
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(var i = 0;i!=s.length;++i){
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }else {
        const buf = new Array(s.length);
        for(let i = 0; i != s.length; ++i){
            buf[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }
}

export function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();
    var dataURL = canvas.toDataURL("image/"+ext);
    return dataURL;
}

function imgToBase64(url) {
    let image = new Image();
    //解决跨域问题
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
    let that = this;
    //image.onload为异步加载
    image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var quality = 0.8;
        //这里的dataurl就是base64类型
        var dataURL = canvas.toDataURL("image/jpeg", quality);//使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
    }
}

function font(sz,bold,color,underline) {
    return {
        name:'微软雅黑',
        sz:sz,
        bold:bold,
        color:{
            rgb:color
        },
        underline:underline
    }
}

function alignment(horizontal,vertical) {
    return {
        horizontal:horizontal,
        vertical:vertical,
        wrapText: true,
    }
}


function addRangeBorder(range,ws){
    let arr = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

    range.forEach(item=>{
        let startRowNumber = Number(item.s.c),
            endRowNumber = Number(item.e.c);

        for(let i = startRowNumber +1;i<= endRowNumber;i++){
            ws[arr[i]+(Number(item.e.r)+1)]= {s:{border:{top:{style:'thin'}, left:{style:'thin'},bottom:{style:'thin'},right:{style:'thin'}}}};
        }
    })
    return ws;
}


let oneList = [
    "1、附加费：详细附加费条款请参考各渠道附加费明细。",
    "",
    "2、产品要求：只接普货、带电产品只接内置纽扣电池产品，只接纸箱包装，托盘包装单询，不接受不规则或异形包装，包括但不限于圆柱形、三角形等。不接任何原木、实木包装以及产品/配件带实木的货件，强烈建议客户改用免熏蒸的胶合板木箱包装.所有产品发货前需要客户自行了解，出口货物是否符合目的地国家进口标准，如因产品本身的问题导致清关纠纷或扣件，客户自行承担责任",
    "",
    "3、申报要求：对于我司拒接和海关禁运的产品进行冲货，以及对有品牌的产品报无牌，产品数量/产品品名等虚假申报，一旦查到将扣货并罚款10000元/票；请如实申报货物品名，所有品名确认可以运输清关才可入仓，如有固定正确的清关编码和税率清关，请及时提供，我们会尽量参考，如不提供，需接受我们国外清关行提供的编码和税率并如实交税；请不要恶意低申报，低申报会导致高查验风险，反倾销，扣货，延期最低请按销售价值的30% 或 采购价的60%。",
    "",
    "4、亚马逊责任：亚马逊电商货物会因为亚马逊自身问题发生额外费用，如亚马逊爆仓货物在码头产生仓租柜租，提前提柜，亚马逊爆仓拒收货物产生返空费，2次派送费及超时卸货费由发件人支付（实报实销）。",
    "",
    "5、查验责任：不包税产品，如遇海关查验，由此可能产生仓租柜租，杂费等，每个柜子按照68CBM平均核算，然后均摊到每个客户，由发件人支付（实报实销）。",
    "",
    "6、出货责任：出货前一定要确认产品是否能正常出运，以免出运后发现问题退运产生巨大损失。做出口退税的客户报关归类要正确，以免被海关查到错误归类货物延期。出运前把所有要准备的资料都准备好，出运前请和销售确认好付款条件以免影响后续放货，送货。发现货物夹带仿牌、仿外形产品、带电、带磁、膏状、液体、粉末颗粒、强磁、化工品等敏感货的瞒报/虚报行为，我司当冲货处理，一旦查到罚款10000元/票。冲货可能导致货物被机场或海关没收，我司概不负责，如另产生额外的费用将实报实销。敬请各位做好自查货工作！"
];

let twoList = [
    "3、根据亚马逊仓 FBA货物规定，如单件重量若超过15KG，在外箱贴上“Heavy Package”(超重标签）。如单件重量在22.5-30KG，在外箱贴上“Team Lift”标签。所有到亚马逊的货物，客户需自行了解亚马逊箱规、单箱重量/尺寸、箱标等各项要求，因客户操作不当导致亚马逊拒收等，所产生的后果需要客户自行承担。",
    "如：【1】产品标/箱标/托盘标没贴或贴错拒收；【2】Team Lift标签 / Mech Lift标签没贴或贴错拒收；【3】绑扎 / 填充材料不当 / 产品标签实物不符等；",
    "",
    "4、外箱要求坚固非破旧箱子，防止货物运输损坏，避免亚马逊仓库拒收造成货物丢失，因包装问题导致的外箱破损，产品损坏我司不承担任何责任。",
    "",
    "5、发货前请贴好产品的FNSKU条码以及FBA外箱标签并用透明胶纸封好（如使用热敏标签纸打印的，请勿再次使用透明胶纸封贴，避免标签纸内容因化学挥发而导致面单不清晰被亚马逊仓库拒接），如因标签条码问题无法递送产生的相关费用则由发件人承担。"
]

let threeList = [
    "1、我司不承运的物品包括：仿牌，玩具，烟草，液体，粉末，食品，鲜货，药品，腐蚀性物品，易燃易爆军火武器等违禁品；如发现瞒报冲货，货物没收，不退货款。如我司未查出，由海关或航空公司查出，引起的所有责任由发件人全部承担。",
];

let fourList = [
    "一、赔偿声明：",
    "",
    "1、 如因我司过错，货物在派送或中转途中发生丢失、损坏、短缺，客户有权向我司提出索赔。 ",
    "",
    "2、 客户的任何索赔应在我司收货后 30 个自然日内以书面形式向我司提出（已签收的快件必须在货物送达后10 个工作日内由客户书面形式提出），否则我司将不承担任何责任。 ",
    "",
    "3、 无论货物的具体价值，我司对每张运单承担赔偿的标准如下：\n",
    "",
    "（1）使用尾程快递派送方式\n",
    "① 以目的国当地快递公司官网的路由信息为准，货物未交给目的国当地快递公司提取之前，若有发生丢失、损毁、海关查扣/没收/销毁等等而导致的货物损失甲方需提供相关产品采购证明，乙方最高赔偿标准为RMB20/KG（并退基础运费，不包括报关费/目的国税金等费用）；货物已交目的国当地快递公司提取之后，若有丢失、损毁，乙方按照每票100美元为最高赔偿金额进行理赔；\n",
    "② 如UPS或FedEx官网所有子单号显示全部提取并且已经递送完成，如果客户需要POD，我司尽力协助索取POD，但不保证能提供手签POD，因提供不了手签POD导致的损失，我司概不承担；如UPS或FedEx官网部分单号显示没有提取或者没有签收，此类情况发货方需配合填写调查表，协助调查，如果最终确认货物丢失，赔偿方案按UPS/FEDEX官方赔偿标准，最高100USD/票。建议客户高货值货物请购买运输保险，我司不予承保；如遇自然灾害等不可抗因素，本司不承担赔偿责任。\n",
    "\n",
    "（2）使用尾程卡车派送方式\n",
    "① 货物未交给目的国当地卡车公司提取之前，若有发生丢失、损毁、海关查扣/没收/销毁等等而导致的货物损失，甲方需提供相关产品采购证明，乙方最高赔偿标准为RMB20/KG（并退基础运费，不包括报关费/目的国税金等费用）；\n",
    "② 货物已交目的国当地卡车公司提取之后，以目的国当地卡车公司递送证明（递送证明即Proof of Delivery，以下简称“POD”）信息为准：\n",
    "【派送亚马逊仓库的】POD有亚马逊仓库签字盖章的，若有丢失、损毁，乙方不受理赔偿；POD没有亚马逊仓库签字盖章的，若有丢失、损毁，乙方不受理赔偿，乙方有义务提供相关的预约号或预约信息，为甲方向亚马逊仓库提起的索赔流程提供必要的协助。\n",
    "【派送非亚马逊仓库的】POD有收货方签字或盖章的，若有丢失、损毁，收货方须在签收现场要求卡车公司派送员在POD上备注丢失、损毁等相关的情况说明，收货方对POD和受损货物拍照留底，并将上述凭证发送我司进行索赔流程，最终赔偿金额以目的国当地卡车公司的赔偿金额为准；POD没有任何签字盖章的，若有丢失、损毁，乙方不受理赔偿；",
    "",
    "4、以下情况我司不予赔偿：\n",
    "（1）尾程派送（UPS/FEDEX等）官网显示递送正常的，不作赔偿；\n",
    "（2）非亚马逊FBA地址件，同批次订单只要有1件显示正常签收，我司默认同批订单全部正常递送，不作理赔；\n",
    "（3）非整箱丢失，属于内装物品短少或破损的，不作赔偿；\n",
    "（4）易碎品不作任何损失赔偿；\n",
    "（5）货物未如实申报、侵权、不符合当地法规、禁止进口而导致海关扣货不作赔偿，并且保留追究发件人因此带来对我司的损失赔偿责任；\n",
    "（6）如遇战争、自然灾害等不可抗拒因素导致货物破损或灭失，不作赔偿；",
    "",
    "5、任何形式的延误或者货物丢失，我司不承担任何间接损失，直接损失的赔偿以上述各条款的最高赔偿标准为限。",
    "",
    "6、客户委托我司托运之货物应符合相关法律法规，不可含有国家有关政府部门规定之不可托运物 品，包括但不限于危险品、液状物体等，货物外包装不得出现违禁品标识。如果客户的货物中有前述禁止中转的货物，我司有权对发货人处以 10000元以上的处罚，且由此产生的海关罚款、 代理罚款等直接损失，我司将向客户收取，客户应在收到相关问题通知后起 3 个工作日内支付。"
]

let fiveList = [
    "1、我司无法保证可以逐一审查所有货箱数据、外箱包装等，如因货物本身、货物信息、货物数据、货箱包装等问题导致海关扣货、服务商/收件人拒收或退回（服务商包括但不限于船公司、航空公司、快递公司、第三方海外仓等），所产生的一切费用及责任由发件方承担。",
    "",
    "2、不符合目的地国家进口要求的货物不能寄运！出口货物需要办理审批、检验、认证等手续的，发件方应当将办理完有关手续的证明文件提交我司，但我司无鉴别货物出口所需授权、检验、认证等资料文件真实性的能力，发件方所提供的相关文件，我司默认其真实有效。如因产品授权、认证等相关资料缺失或者无效，导致货物退运、查没、扣留、销毁等产生的所有责任和相关费用将由发件方承担，给我司或第三人造成经济损失的，甲方应承担赔偿责任。",
    "",
    "3、目的地海关认定货物是品牌货物，或者是CE、蓝牙、HDMI、FDA、FCC、Lacey Act、DOT等认证和授权的问题，或者货物属于当地反倾销货物或不符合目的地国家进口要求的货物，客人需要提供相应授权书和认证报告，（如下货物需要CE认证或产品上有CE标志：包括电子产品、电器、灯具、玩具类、机械类、带电产品。其中玩具因为涉及儿童健康，还需要ENT1标准测试报告，主要涉及无毒无害证明，玩具上还要有具体吊牌商标适合年龄段等等详细的说明）。",
    "",
    "4、因不可抗拒因素造成的延误和丢件，我司不承担赔偿责任。（不可抗抗拒因素包括但不限于地震、泥石流、洪涝灾害；台风、暴雨雪、雾霾等恶劣天气；罢工、恐怖事件、意外交通事故、法规政策的修改、政府、海关等司法机关的行为、决定或命令；抢劫、飞车抢夺等暴力犯罪等）",
    "",
    "5、托寄物可能获得的收益、利润、实际用途、商业机会、商业价值等任何间接损失，我司不承担赔偿责任。"
];

let tableData = [
    {name:"入仓单",num:"1元/张",detail:"无携带入仓单，现场打印",status:0},
    {name:"分货费",num:"1元/箱",detail:"按总箱数计费，最低消费50元/票。一车货物超过3个单的，没有贴入仓标签也没有写分货记号的。",status:0},
    {name:"贴标（FBA标）",num:"1元/张",detail:"单票200元封顶",status:3},
    {name:"贴标（唛头）",num:"1元/张",detail:"标准尺寸唛头必须是10*10cm,不得少于10*10cm。（单票200元封顶）",status:4},
    {name:"贴标（产品SKU)",num:"1元/个",detail:"注：客户提供标签或到仓贴标同样1元/张收取服务费。",status:0},
    {name:"换箱",num:"15元/箱",detail:"含纸箱、两张FBA标签、唛头。（如需定制特殊尺寸的纸箱，费用单独确认！）",status:1},
    {name:"换箱（客户提供纸箱）",num:"5元/箱",detail:"含两张FBA标签、唛头。",status:0},
    {name:"改箱、合箱",num:"15元/箱",detail:"含人工物料、两张FBA标签、唛头。",status:0},
    {name:"打卡板",num:"150元/板",detail:"含卡板、缠膜、托盘唛头。",status:0},
    {name:"打卡板（客户提供卡板）",num:"50元/板",detail:"含缠膜、托盘唛头。",status:0},
    {name:"挑货",num:"1元/箱",detail:"按总箱数计费，最低消费50元/票。",status:0},
    {name:"仓租",num:"阶梯收费",status:2,detail:"（1）免仓期：7个自然日（入仓当天算起）|\n" +
        "（2）逾期仓储费|\n" +
        "   逾期一个月（第1-30天）之内，按照2元/cbm/自然日收取存储费。|\n" +
        "   逾期二个月（第31-60天），按照3元/cbm/自然日收取存储费。|\n" +
        "   逾期三个月（第61-90天），按照4元/cbm/自然日收取存储费。|\n" +
        "   同时客服提前7天邮件通知客户是否继续续费存储，费用翻倍以此类推；|\n" +
        "（2）对未缴纳存储费的客户，客服邮件提醒通知客户，存储周期为三个月（90天）未交费，定义客户自动弃货，我公司有权自行处理。"},
    {name:"复核费",num:"1元/箱",detail:"复核数据比初次数据尺寸超0.5cm或重量超1kg，按初次数据为准，另需加收挑货费。|\n" +
        "若复核数据低于初次入库数据，按复核数据免费更新数据，不加收挑货费。",status:2},
    {name:"退仓费",num:"15元/cbm/吨",detail:"最低收费50元/票。非我司原因导致的退仓：如产品不符合进出口要求、卖家账号异常等，如客户需现金结算等。",status:0},
];

let sixList = [
    "1、要求一个SKU下一票单，且每箱货物产品装箱数量相同，不接受混装货物。",
    "2、单票货物低消，计重300KG，计方2CBM。不接受多票合并计费。",
    "3、外箱必须贴上我司特定留仓货物入仓标签，每箱贴2张。"
]

let tableData2 = [
    {name:"贴标费",sm:'FBA外箱标签',num:'USD0.6/张',detail:'1箱2张',status:0},
    {name:"贴标费",sm:'快递面单标签',num:'USD0.6/张',detail:'1箱2张',status:0},
    {name:"贴标费",sm:'产品SKU标签',num:'USD0.5/张',detail:'/',status:0},
    {name:"仓储费",sm:'15-60天',num:'USD8/CBM/周',detail:'免仓期14天，到仓拆柜当天起算（不足一周，按一周计费）',status:0},
    {name:"仓储费",sm:'61-90天',num:'USD10/CBM/周',detail:'（不足一周，按一周计费）',status:0},
    {name:"仓储费",sm:'91-180天',num:'USD12/CBM/周',detail:'（不足一周，按一周计费）',status:0},
    {name:"仓储费",sm:'181天+',num:'USD15/CBM/天',detail:'（不足一周，按一周计费）超过365天单询',status:0},
    {name:"出仓费",sm:'客户自提出仓',num:'USD20/托',detail:'/',status:0},
    {name:"出仓费",sm:'巨宝卡派出仓',num:'USD15/托',detail:'/',status:0},
    {name:"出仓费",sm:'巨宝快递出仓',num:'USD15/托',detail:'/',status:0},
    {name:"分拣费（如需）",sm:'整票发出',num:'无',detail:'/',status:0},
    {name:"分拣费（如需）",sm:'随机部分发出',status:2,num:'USD0.5/箱',detail:'单票货物如需分批发货，不指定箱号|\n' +
        '例：一票货物200箱|  \n' +
        '第一次出10箱  分拣费：0.5$*10箱|\n' +
        '第二次出20箱  分拣费：0.5$*20箱|\n' +
        '...以此类推'},
    {name:"打托费",sm:'USD15/托',num:'USD15/托',detail:'中转货物出仓时，卡车或快递派送均需要打托',status:0},
    {name:"派送费",sm:'单询',num:'单询',detail:'/',status:0},
    {name:"订单处理费",sm:'USD20/次（即每个发货指令）',num:'USD20/次（即每个发货指令）',detail:'/',status:0},
    {name:"单次操作低消",sm:'客户自提出仓',num:'USD50',detail:'贴标费+出仓费+分拣费<低消时，按USD50收取',status:0},
    {name:"单次操作低消",sm:'使用巨宝派送',num:'USD40',detail:'贴标费+出仓费+分拣费<低消时，按USD40收取',status:0},
];

let tableData3 = [
    {name:"拍照片",sm:'USD1/张',num:'USD1/张',detail:'/',status:0},
    {name:"开箱操作费",sm:'USD1/箱',num:'USD1/箱',detail:'/',status:0},
    {name:"封箱费",sm:'USD3/箱',num:'USD3/箱',detail:'/',status:0},
    {name:"销毁费",sm:'单询',num:'单询',detail:'如需弃货销毁',status:0},
]

export const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

let apiUrl = "https://gwapi.hubologistics.com";
// let apiUrl = "https://api.test.gw.hubologistics.com";

export {
    oneList,
    twoList,
    threeList,
    fourList,
    fiveList,
    tableData,
    tableData2,
    tableData3,
    sixList,
    apiUrl
}